body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Swipers Demo */
.demo-swiper {
  width: 100%;
  height: 100%;
}
.demo-swiper .swiper-slide,
.demo-swiper-multiple .swiper-slide {
  font-size: 25px;
  font-weight: 300;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background: #fff;
  color: #000;
}

.swiper-button-next:focus{
  outline: none !important;
}

.swiper-button-prev:focus {
  outline: none !important;
}



.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
