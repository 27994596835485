.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.center-col {
  display: flex;
  align-items: center;
  justify-content: center;
}


.plot {
  position: absolute; 
  top: 0; 
  left: 0; 
  bottom: 0; 
  right: 0; 
}

.plot-wrapper {
  background-color: lightskyblue; 
  position: relative; 
  width: 100%; 
  padding-top: 56.25%; /* 16:9 Aspect Ratio */ 
}

.calendar-inline-container{
  width:100%;
  height: 100%;
}


